import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormConfig } from '../models/dynamic.model';
import { CommonService } from './common.service';
import { alphanumericWithSpacePattern } from '../utility/constants';

@Injectable({
  providedIn: 'root',
})
export class ValidatorService {
  constructor(
    private readonly fb: FormBuilder,
    private readonly commonService: CommonService
  ) {}

  public generateForm(jsonConfig: any): {
    form: FormGroup;
    errorMessages: any;
    options: any;
  } {
    const form = this.createForm(jsonConfig);
    const errorMessages = this.setErrorMessageObject(jsonConfig);
    const options = this.getSelectOptions(jsonConfig);
    this.handleRestriction(form, jsonConfig);
    this.noInitialWhiteSpace(form, jsonConfig);
    return { form, errorMessages, options };
  }

  public createForm(formConfig: FormConfig): FormGroup {
    const formGroup: any = {};
    for (const field of formConfig.fields) {
      const validators = [];
      if (field.required) {
        validators.push(Validators.required);
      }
      if (field.minValue) {
        validators.push(Validators.minLength(field.minValue));
      }
      if (field.pattern) {
        validators.push(Validators.pattern(field.pattern));
      }
      formGroup[field.id] = [
        field.isDisableField ? { value: '', disabled: true } : '',
        validators,
      ];
    }
    return this.fb.group(formGroup);
  }

  public getErrorMessage(
    form: FormGroup,
    errorMessages: any,
    fieldId: string
  ): string {
    const control = form.get(fieldId);
    const fieldErrorMessages = errorMessages[fieldId];
    if (control?.errors && fieldErrorMessages) {
      const errorKey = Object.keys(control.errors)[0];
      return fieldErrorMessages[errorKey] || '';
    }

    return '';
  }

  private setErrorMessageObject(formConfig: any): any {
    const errorMessages: any = {};
    for (const field of formConfig.fields) {
      if (
        field.requiredError ||
        field.patternError ||
        field.minError ||
        field.emailPatternError ||
        field.patternError ||
        field.phonePatternError ||
        ''
      ) {
        errorMessages[field.id] = {
          required: field.requiredError || '',
          pattern:
            field.emailPatternError ||
            field.patternError ||
            field.phonePatternError ||
            '',
          minlength: field.minError || '',
        };
      }
    }
    return errorMessages;
  }

  private getSelectOptions(jsonConfig: any): {
    selectOptions: any[];
    radioOptions: any[];
  } {
    const selectField = jsonConfig.fields.find(
      (field: any) => field.type === 'select'
    );
    const radioField = jsonConfig.fields.find(
      (field: any) => field.type === 'radio'
    );
    const selectOptions = selectField ? selectField.options : [];
    const radioOptions = radioField ? radioField.options : [];
    return { selectOptions, radioOptions };
  }

  private handleRestriction(form: FormGroup, formConfig: any) {
    for (const field of formConfig.fields) {
      if (field.allowNumeric) {
        const control = form.get(field.id);
        if (control) {
          control.valueChanges.subscribe(value => {
            if (
              typeof value === 'string' &&
              this.commonService.isValidField(value)
            ) {
              const sanitizedValue = value.replace(/\D/g, ''); // Allows only numbers
              const trimmedValue = sanitizedValue.slice(0, field.maxValue); // Limit to maximum
              if (value !== sanitizedValue || sanitizedValue !== trimmedValue) {
                control.setValue(trimmedValue, { emitEvent: false });
              }
            }
          });
        }
      } else if (field.allowAlphabet) {
        const control = form.get(field.id);
        if (control) {
          control.valueChanges.subscribe(value => {
            if (this.commonService.isValidField(value)) {
              const sanitizedValue = value && value.replace(/[^a-zA-Z]/gi, ''); // Allows only alphabets
              const trimmedValue = sanitizedValue.slice(0, field.maxValue); // Limit to maximum
              if (value !== sanitizedValue || sanitizedValue !== trimmedValue) {
                control.setValue(trimmedValue, { emitEvent: false });
              }
            }
          });
        }
      } else if (field.allowAlphaNumeric) {
        const control = form.get(field.id);
        if (control) {
          control.valueChanges.subscribe(value => {
            if (
              typeof value === 'string' &&
              this.commonService.isValidField(value)
            ) {
              // Check if value is a string
              const sanitizedValue = value.replace(/[^a-zA-Z0-9]/gi, ''); // Allows only alphabets and numbers
              const trimmedValue = sanitizedValue.slice(0, field.maxValue); // Limit to maximum
              if (value !== sanitizedValue || sanitizedValue !== trimmedValue) {
                control.setValue(trimmedValue, { emitEvent: false });
              }
            }
          });
        }
      } else if (field.allowAlphabetWithSpace) {
        const control = form.get(field.id);
        if (control) {
          control.valueChanges.subscribe(value => {
            if (this.commonService.isValidField(value)) {
              if (value !== ' ') {
                const sanitizedValue =
                  value && value.replace(/[^a-zA-Z ]/gi, ''); // Allows only alphabets with space
                const trimmedValue = sanitizedValue.slice(0, field.maxValue); // Limit to maximum
                if (
                  value !== sanitizedValue ||
                  sanitizedValue !== trimmedValue
                ) {
                  control.setValue(trimmedValue, { emitEvent: false });
                }
              } else {
                const trimmedValue = value && value.trimStart(); // Remove initial white space
                if (value !== trimmedValue) {
                  control.setValue(trimmedValue, { emitEvent: false });
                }
              }
            }
          });
        }
      } else if (field.allowAlphaNumericWithDotUnderscoreDashSpecialCharacters) {
        const control = form.get(field.id);
        if (control) {
          control.valueChanges.subscribe(value => {
            if (this.commonService.isValidField(value)) {
              // Regex allows alphabets, numbers, underscore, dash, and dot
              const sanitizedValue = value.replace(/[^a-z0-9._-]/gi, ''); // Allows alphabets, numbers, _, -, and .
              const trimmedValue = sanitizedValue.slice(0, field.maxValue); // Limit to maximum
              if (value !== sanitizedValue || sanitizedValue !== trimmedValue) {
                control.setValue(trimmedValue, { emitEvent: false });
              }
            }
          });
        }
      } else if (field.allowAlphaNumericWithSpaceNoSpecialCharacter) {
        const control = form.get(field.id);
        if (control) {
          control.valueChanges.subscribe(value => {
            if (this.commonService.isValidField(value)) {
              // Regex allows alphabets, numbers, space
              const sanitizedValue = value.replace(alphanumericWithSpacePattern, '');
              const trimmedValue = sanitizedValue.slice(0, field.maxValue);
              if (value !== sanitizedValue || sanitizedValue !== trimmedValue) {
                control.setValue(trimmedValue, { emitEvent: false });
              }
            }
          });
        }
      } else if (form.get(field.id) && field.isCheckForMaxValue) {
        const control = form.get(field.id);
        control?.valueChanges.subscribe(value => {
          if (value !== ' ') {
            const trimmedValue = (value || '').slice(0, field.maxValue); // Limit to maximum
            if (value !== trimmedValue) {
              control.setValue(trimmedValue, { emitEvent: false });
            }
          }
        });
      }
    }
  }

  private noInitialWhiteSpace(form: FormGroup, formConfig: any) {
    for (const field of formConfig.fields) {
      if (field.noInitialWhiteSpace) {
        const control = form.get(field.id);
        if (control) {
          control.valueChanges.subscribe(value => {
            if (typeof value === 'string') {
              // Check if value is a string
              const trimmedValue = value.trimStart(); // Remove initial white space
              if (value !== trimmedValue) {
                control.setValue(trimmedValue, { emitEvent: false });
              }
            }
          });
        }
      }
    }
  }
}
